/**
 * Parse Availability date from the API call
 * @param dateString
 * @constructor
 */
export const ParseAvailabilyDate = (dateString: Date): Date => {
    try {
        // Convert the input date to an ISO string
        const isoString = new Date(dateString).toISOString();

        // Extract the year, month, and day from the ISO string
        const year = parseInt(isoString.substring(0, 4), 10); // Extract year (YYYY)
        const month = parseInt(isoString.substring(5, 7), 10) - 1; // Extract month (MM) and subtract 1 (months are 0-indexed)
        const day = parseInt(isoString.substring(8, 10), 10); // Extract day (DD)

        // Check if the values make sense (valid date)
        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            throw new Error('Invalid date components');
        }

        // Create and return a new Date object with the extracted values
        return new Date(year, month, day);
    } catch (error) {
        // Return the original input value if any error occurs
        console.error(`Error processing date: ${(error as Error).message}`);
        return dateString;
    }
};
